import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { ReactNode } from 'react';

interface IModalProps {
  title: string,
  message: ReactNode,
  show: boolean,
  handleClose(): void,
}


const useStyles = makeStyles({
  paper: {
    position: 'absolute',
    width: 550,
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    textAlign: "center",
    padding: "25px",
    borderRadius: "6px",
    border: "1px solid rgba(0,0,0,.2)"
  }
});


const ModalMessageWithNode = (props: IModalProps) => {
  const classes = useStyles();

  const body = (
    <div className={classes.paper}>
      <Grid container spacing={0} justifyContent="center">
        <Grid item={true} md={11}>
          <Typography variant="subtitle1" style={{ paddingLeft : "10%" }} >{props.title}</Typography>
        </Grid>
        <Grid item={true} md={1}>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={props.handleClose}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={0} justifyContent="center">
        {props.message}
      </Grid>
    </div>
  );

  if (props.show === false) {
    return null;
  }

  return (
    <>
      <Modal
        open={props.show}
        onClose={props.handleClose}
      >
        {body}
      </Modal>
    </>
  );
}

export default ModalMessageWithNode;