import { Route, Redirect, Switch } from 'react-router-dom';
import { MainAuthPath, VoRoutePath, ApiRoutePath, UserInfoPath, UserSettingLogs, DataImportPath, CreateDbBackUpPagePath, KaFlRoutePath} from './../../../Interface/RouteConst';
import { connect, ConnectedProps} from 'react-redux';
import { ApplicationState } from './../../../store/index';
import lazyWithRetry from './../../Common/LazyWithRetry/lazyWithRetry';

const ApiRoutePage = lazyWithRetry( ()=> import('./../../SoapApi/Route/Route') );
const StartPage = lazyWithRetry(() => import('./../../Main/StartPage/StartPage'));
const VoRoutePage = lazyWithRetry(() => import('./../../VO/Route/VoRouteTheme'));
const UserInfo = lazyWithRetry(() => import('./../../Main/UserInfo/UserInfo'));
const SettingLogs = lazyWithRetry(() => import('./../SettingLogs/SettingLogs'));
const ImportPage = lazyWithRetry(() => import('./../ImportPage/ImportPage'));
const CreateDbBackUpPage =  lazyWithRetry(() => import('../CreateDbBackUpPage/CreateDbBackUpPage'));
const KaFlRoutePage =  lazyWithRetry(() => import('./../../KaFl/Route/KaFlRoute'));


const mapStateToProps = (state : ApplicationState) => ({
  isAuth : state.appAuth.appAuth.isAuth
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;



function PrivateRouts(props : PropsFromRedux){

  
  
  if( props.isAuth === false)
  {
    return <Redirect to={MainAuthPath} />;
  }

      return (
        <Switch>
          {/* маршруты настройки, требующий общего уровня авторизации */}
          <Route path={UserInfoPath}            component={UserInfo} />
          <Route path={UserSettingLogs}         component={SettingLogs} />
          <Route path={DataImportPath}          component={ImportPage} />
          <Route path={CreateDbBackUpPagePath}  component={CreateDbBackUpPage} />

          {/* маршруты отдельных разделов приложения */}
          <Route path={VoRoutePath}             component={VoRoutePage} />
          <Route path={ApiRoutePath}            component={ApiRoutePage} />
       
          <Route path={KaFlRoutePath}           component={KaFlRoutePage} />
          <Route component={StartPage} />
        </Switch>
      );
}


export default connector(PrivateRouts);
