import * as Error from './Error';
import * as Auth from './AuthStatus';
import * as VoAth from './VoAuth';
import * as StudentJournalsInfo from './StudentJournalsInfo';
import * as HttpErrorMessages from './HttpErrorMessages';
import * as HttpWarningMessages from './HttpWarningMessages';
import * as KaFlAuth from './KaFlAuth';

export interface ApplicationState {
    error :  Error.ErrorState,
    appAuth : Auth.AuthStatusState,
    voAuth : VoAth.VoAuthState,
    studentJournalsInfo : StudentJournalsInfo.VoStudentJournalsInfoState,
    httpErrorMessages: HttpErrorMessages.HttpErrorMessagesState,
    httpWarningMessages : HttpWarningMessages.HttpWarningMessagesState,
    kaFlAuth : KaFlAuth.KaFlAuthState
}


export const reducers = {
    error: Error.reducer,
    appAuth : Auth.reducer,
    voAuth : VoAth.reducer,
    studentJournalsInfo : StudentJournalsInfo.reducer,
    httpErrorMessages : HttpErrorMessages.reducer,
    httpWarningMessages : HttpWarningMessages.reducer,
    kaFlAuth : KaFlAuth.reducer
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
