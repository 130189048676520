import { IStateType, IModules, ISiteTheme, ISiteList } from './../Interface/MainTypes';

export function GetVoTheme(modules: IModules[] | null, stateType: IStateType): ISiteTheme {
    const defaultValueTheme: ISiteTheme = { id: 0, name: "Default" };
  
    if (modules === null)
      return defaultValueTheme;
  
    let siteTheme: ISiteTheme | undefined = undefined;
  
    if (stateType === 'Desktop') {
      siteTheme = modules.find(x => x.id === ISiteList.voDesktop)?.siteTheme;
    }
    else {
      siteTheme = modules.find(x => x.id === ISiteList.voWeb)?.siteTheme;
    }
  
    if (siteTheme)
      return siteTheme;
  
    return defaultValueTheme;
  }