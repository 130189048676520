import { IModalType } from "../../../Interface/MainTypes";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    top: "3%"
  },
  closeButton: {
    color: "#ccc",
  },
});

interface IProps {
  title: string,
  messages: Array<string> | null,
  id : string,
  handleClose(id : string) : void,
  type: IModalType
}

interface IPropsList {
  list: Array<string> | null
}

const ShowList = (props: IPropsList) => {
  if (props.list === null)
    return null;

  return (
    <>
      {props.list.map((item, index) => (
        <Typography key={index} variant="subtitle2" >{item}</Typography>
      ))}
    </>
  );
}

const ModalList = (props: IProps) => {
    const classes = useStyles();
    const onCloseModal = () => props.handleClose(props.id);


  return (
    <Dialog
      open={true}
      onClose={onCloseModal}
      scroll="paper"
      fullWidth = { true } 
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container spacing={0} justifyContent="center" alignItems="center" >
          <Grid item={true} md={11}>
            <Alert severity={props.type}>{props.title}</Alert>
          </Grid>
          <Grid item={true} md={1} style={ { display: "flex", justifyContent:"flex-end" } }>
              <IconButton aria-label="close" onClick={onCloseModal} className={classes.closeButton}>
                <CloseIcon fontSize="small" />
              </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={true} >
        <DialogContentText
          tabIndex={-1}
        >
          <ShowList list={props.messages} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default ModalList;