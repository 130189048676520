import { IAuthStatus } from './Interface/MainTypes';
import { connect, ConnectedProps } from 'react-redux';
import { setError } from './store/Error';
import { useState, useEffect } from 'react';
import { UpdateAppStateToTimer, SetStartUpdateStatus } from './store/AuthStatus';

export const mapDispatchToProps = (dispatch: any) => {
  return {
    updateAppState: (serverState: IAuthStatus) => dispatch(UpdateAppStateToTimer(serverState)),
    setStartUpdateStatus: () => dispatch(SetStartUpdateStatus()),
    setError: (error: Error) => dispatch(setError(error))
  }
}

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const GetRequest = (url : string) => {
  const baseUrl = window.location.origin;
  const fullPath = `${baseUrl}/${url}`;
  return new Request(fullPath, { method: "get" });
}

const RequestStatus = (props: PropsFromRedux) => {
  const [intervalId, setIntervalId] = useState<null | NodeJS.Timeout>(null);

  const ClearTimer = () => {
    if (intervalId)
      clearInterval(intervalId);
  }

  useEffect(() => {
    const path = "api/main/stateType";
    const request = GetRequest(path);

    fetch(request)
      .then((response) => {
        return response.text();
      })
      .then((serverStateType) => {
        if (serverStateType === "Desktop") {
          let intervalID = setInterval(shortAjax, 3000);
          setIntervalId(intervalID);
        }
      })

    return () => ClearTimer();
  }, [])

  const shortAjax = () => {
    
    const path = "api/main/authAuto";
    const request =  GetRequest(path);

    fetch(request)
      .then((response) => {
        if (false === response.ok) {
          props.setError(new Error("error getStatus"));
          return;
        }
        return response.json();
      })
      .then((data) => {
        const serverState: IAuthStatus = data as IAuthStatus;
        props.updateAppState(serverState);
      })
      .catch(error => {
        props.setStartUpdateStatus();
      })
  }

  return null;
}

export default connector(RequestStatus);