import  { Component, ErrorInfo, ReactNode } from "react";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { post } from './../../../Utils/Fetch';
import {apiWriteError} from './../../../Interface/ServerRouteConst';
import { IComponentStatus, ClientError } from './../../../Interface/MainTypes';
import  Loader  from './../../Common/Loader/Loader';
import { ResetApp } from './../../../store/Error';
import { connect, ConnectedProps } from 'react-redux';

interface OwnProps {
  children: ReactNode
}
const mapDispatchToProps = (dispatch : any) =>{
  return {
    unSetError : () => dispatch(ResetApp())
  }
}
const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;


type IProps = OwnProps & PropsFromRedux;

interface State {
  hasError: boolean;
  status : IComponentStatus
}
// компонет отображается при деларативных ошибках, связанных с рендариногом
// https://ru.reactjs.org/docs/error-boundaries.html

class ErrorBoundary extends Component<IProps, State> {

  public state: State = {
    hasError: false,
    status : 'idle'
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true, status : 'idle' };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) 
  {
    const customErr = new ClientError(error.name, error.message, error.stack);
    
    post<ClientError, boolean >(apiWriteError, customErr)
      .then( (response : boolean ) =>{
        this.setState( { status : "success" } );
      })
      .catch( (error : any) =>{})
  }

  public TryRefresh = (e : React.MouseEvent<HTMLButtonElement>) =>
  {
    this.setState( { status : "idle", hasError : false  } );
    this.props.unSetError();
  }

  public render() {
    

    if (this.state.hasError) {

      if(this.state.status === 'pending'  || this.state.status === 'idle')
        return Loader;
  

      return (
      <Grid container spacing={0} justifyContent="center">
      <Grid item={true} lg={8}  md={12}  xs={12} sm={12} style = { { textAlign : "center" } } >
          <h3>Произошла ошибка</h3>
          <Button 
              onClick = {this.TryRefresh}
              variant="contained" 
              color="primary" 
              size="small">
                Перейти на главную страницу 
          </Button>
      </Grid>
    </Grid>);
    }

    return this.props.children;
  }
}

export default connector(ErrorBoundary);