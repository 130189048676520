async function http<T>(path: string, config: RequestInit): Promise<T> {
  let responseStatus: number | undefined = undefined;
  let responseStatusText : string | undefined = undefined;

  const baseUrl = window.location.origin;
  const fullPath = `${baseUrl}/${path}`;

  try {
    const request = new Request(fullPath, config);
    const response = await fetch(request);
    
    responseStatus = response.status;
    responseStatusText = response.statusText;

    if (false === response.ok) {
      throw new Error("Failed_request");
    }

    return response.json();
  }
  catch (error) {
    const message = error instanceof Error ? error.message : String(error);
    const stacktrace = error instanceof Error && error.stack ? error.stack : '';
    const msg = MessageFormatHttp(fullPath, responseStatus, responseStatusText, message, stacktrace, config.body, config.method);
    console.log(msg);
    throw new Error(msg);
  }
}

function MessageFormatHttp(fullPath : string, responseStatus: number | undefined, responseStatusText: string | undefined, message: string, stacktrace: string, body?: BodyInit | null | undefined, method?: string | undefined) : string {
  const methodLabel : string = `method = ${method};`;
  const urlLabel : string = `url = ${fullPath};`;
  const responseStatusLabel : string = `responseStatus = ${responseStatus};`;
  const responseStatusTextLabel : string = `responseStatusText = ${responseStatusText};`;
  const bodyLabel : string = `body = ${body?.toString()};`;
  const messageLabel : string = `message = ${message};`;
  const stacktraceLabel : string = `innerStack = ${stacktrace};`;

  return `HttpRequestError ${methodLabel} ${urlLabel} ${responseStatusLabel} ${responseStatusTextLabel} ${bodyLabel} ${messageLabel} ${stacktraceLabel}`;
}

export async function get<T>(path: string, config?: RequestInit): Promise<T> {
  const init = { method: 'get', ...config }
  return await http<T>(path, init);
}

export async function post<T, U>(path: string, body: T, config: RequestInit | null = null): Promise<U> {
  if (config === null)
    config = { headers: { 'Content-Type': 'application/json' } };

  const init = { method: 'post', body: JSON.stringify(body), ...config }
  return await http<U>(path, init);
}

export async function put<T, U>(path: string, body: T, config: RequestInit | null = null): Promise<U> {
  if (config === null)
    config = { headers: { 'Content-Type': 'application/json' } };
  const init = { method: 'put', body: JSON.stringify(body), ...config }
  return await http<U>(path, init);
}

export async function deleteData<T>(path: string, config?: RequestInit): Promise<T> {
  const init = { method: 'delete', ...config }
  return await http<T>(path, init);
}

