import Grid from '@material-ui/core/Grid';
import { Alert, AlertTitle } from '@material-ui/lab';
import Loader from './../../Common/Loader/Loader';
import { ApplicationState } from './../../../store/index';
import { Redirect } from 'react-router-dom';
import { StartPagePath } from './../../../Interface/RouteConst';
import { connect, ConnectedProps } from 'react-redux';
import { useEffect, useState } from 'react';

const mapStateToProps = (state: ApplicationState) => ({
  updateting: state.appAuth.appAuth.updateProcess
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const StartUpdateInfo = (props: PropsFromRedux) => {
  const [hasErrorUpdate, setHasErrorUpdate] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => setHasErrorUpdate(true), 1200000);  

    return () => clearTimeout(timeout);
  }, [])

  let message : JSX.Element | null = null;

  if(hasErrorUpdate === false)
  {
    message = <Alert severity="info"><AlertTitle>Приложение обновляется</AlertTitle>Пожалуйста, подождите</Alert>
  }
  else
  {
    message = <Alert severity="error"><AlertTitle>Ошибка обновления приложения</AlertTitle>Пожалуйста, обратитесь к администратору</Alert>
  }
  

  if (props.updateting === false)
    return <Redirect to={StartPagePath} />

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item={true} lg={8} md={12} xs={12} sm={12} style={{ textAlign: "center" }}>
        {message}
        {Loader}
      </Grid>
    </Grid>
  );
}

export default connector(StartUpdateInfo);