import { Action, Reducer } from 'redux';
import { ISkrinResponseMessages } from './../Interface/MainTypes';


export interface HttpErrorMessagesState {
    httpErrorMessages: Array<ISkrinResponseMessages>
}

export interface SetHttpErrorMessagesAction { type: 'SET_HTTP_ERROR_MESSAGES_INFO', messages: ISkrinResponseMessages }
export interface SetClearHttpErrorMessagesAction { type: 'SET_HTTP_ERROR_MESSAGES_CLEAR_INFO', id: string }

export const SetHttpErrorMessages = (messages: ISkrinResponseMessages): SetHttpErrorMessagesAction => (
    { type: 'SET_HTTP_ERROR_MESSAGES_INFO', messages }
)

export const SetClearHttpErrorMessages = (id: string): SetClearHttpErrorMessagesAction => (
    { type: 'SET_HTTP_ERROR_MESSAGES_CLEAR_INFO', id }
)

type KnownAction = SetHttpErrorMessagesAction | SetClearHttpErrorMessagesAction;

export const reducer: Reducer<HttpErrorMessagesState> = (state: HttpErrorMessagesState | undefined, incomingAction: Action): HttpErrorMessagesState => {

    if (state === undefined) {
        return { httpErrorMessages: [] };
    }
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'SET_HTTP_ERROR_MESSAGES_INFO':
            return { httpErrorMessages: [...state.httpErrorMessages, action.messages] };
        case 'SET_HTTP_ERROR_MESSAGES_CLEAR_INFO':
            const newItems : Array<ISkrinResponseMessages> = state.httpErrorMessages.filter(item => (false === (item.id === action.id)));
            return { httpErrorMessages: newItems };
        default:
            return state;
    }

};

