import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useState } from 'react';
import { IAuth, IComponentStatus } from '../../../Interface/MainTypes';
import Loader from './../../Common/Loader/Loader';
import { post } from './../../../Utils/Fetch';
import { apiCheckUser } from './../../../Interface/ServerRouteConst';
import { IAuthStatus } from '../../../Interface/MainTypes';
import PasswordInput from '../../Common/PasswordInput/PasswordInput';

interface IPropsShowError {
  message: string | null
}

const ShowErrorMessage = (props: IPropsShowError) => {
  if ((props.message === null) || (props.message.length === 0))
    return null;

  return (<span style={{ color: "red", textAlign: "center" }}>{props.message}</span>)
}

interface IProps {
  handleSuccess(): void // при успешной автризации 
}

const AccessPage = (props: IProps) => {

  const [status, setStatus] = useState<IComponentStatus>('idle');
  const defaultValue: IAuth = { login: "", password: "" };
  const [auth, setAuth] = useState<IAuth>(defaultValue);
  const [messageError, setMessageError] = useState<string | null>(null);

  const handelChangeLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value: login } } = e;
    const newAuth = { ...auth, login: login };
    setAuth(newAuth);
  }

  const handelChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value: password } } = e;
    const newAuth = { ...auth, password: password };
    setAuth(newAuth);
  }

  const setPassword = (value : string) => {
    const newAuth = { ...auth, password: value };
    setAuth(newAuth);
  }

  const handelSend = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (auth.login.length > 0 && auth.password.length > 0) {
      setMessageError("");
      setStatus('pending');
      post<IAuth, IAuthStatus>(apiCheckUser, auth)
        .then((response: IAuthStatus) => {
          setStatus('success');
          if (response.isAuth) {
            props.handleSuccess();
          }
          else {
            setMessageError(response.errorMessage);
          }
        })
    }
  }



  const Form: JSX.Element = (
    <form noValidate autoComplete="off" >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={12} md={12} >
          <TextField
            label="Логин"
            fullWidth
            onChange={handelChangeLogin}
            value={auth.login}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <PasswordInput 
            value={auth.password}
            setValue={setPassword}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={0} justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handelSend}
            >Вход</Button>
          </Grid>
        </Grid>
      </Grid>
    </form>);

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item={true} lg={12} md={12} xs={12} sm={12}  >
        <Grid container spacing={0} justifyContent="center">
          <ShowErrorMessage message={messageError} />
        </Grid>
      </Grid>
      <Grid item={true} lg={7} md={7} xs={12} sm={12}  >
        {status === 'pending' ? Loader : Form}
      </Grid>
    </Grid>
  );
}

export default AccessPage;