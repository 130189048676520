import React from "react";
import { Alert, AlertProps } from "@material-ui/lab";
import DoneAllIcon from '@material-ui/icons/DoneAll';

// вместо Alert заменить на AlertIconOverride - для всего проекта целиком 


const AlertIconOverride = (props: AlertProps) => {

  // замена иконки успешного сообщения - похоже на лого СБЕРА
  // null - заначение по умолчанию для Icons

  const getIcon = (): React.ReactNode => {
    switch (props.severity) {
      case "success":
        return <DoneAllIcon />;
      default:
        return null;
    }
  };


  return (
    <Alert icon={getIcon()} {...props}>
      {props.children}
    </Alert>
  );
}

export default AlertIconOverride;