
import Grid from '@material-ui/core/Grid';
import { Alert, AlertTitle } from '@material-ui/lab';
import Loader from '../Loader/Loader';
import { ApplicationState } from '../../../store/index';
import { Redirect } from 'react-router-dom';
import { StartPagePath } from '../../../Interface/RouteConst';
import { connect, ConnectedProps } from 'react-redux';

const mapStateToProps = (state: ApplicationState) => ({
  blockDbChanges: state.appAuth.appAuth.blockDbChanges
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;


const StartUpdateDbInfo = (props: PropsFromRedux) => {

  if (props.blockDbChanges === false)
    return <Redirect to={StartPagePath} />

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item={true} lg={8} md={12} xs={12} sm={12} style={{ textAlign: "center" }}>
        <Alert severity="info"><AlertTitle>База данных приложения обновляется</AlertTitle>Пожалуйста, подождите</Alert>
        {Loader}
      </Grid>
    </Grid>
  );
}

export default connector(StartUpdateDbInfo);