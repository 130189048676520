import { Action, Reducer } from 'redux';
import { IStudentJournalsFilterInfo } from './../Interface/VoTypes';

export interface VoStudentJournalsInfoState {
  studentJournalsInfo: IStudentJournalsFilterInfo
}

export interface SetJournalsInfoAction { type: 'SET_VO_STUDENT_JOURNALS_INFO', info: IStudentJournalsFilterInfo }
export interface SetClearJournalsInfohAction { type: 'SET_VO_STUDENT_JOURNALS_CLEAR_INFO' }

export const SetJournalsInfo = (info: IStudentJournalsFilterInfo): SetJournalsInfoAction => (
  { type: 'SET_VO_STUDENT_JOURNALS_INFO', info }
)

export const SetClearJournalsInfo = (): SetClearJournalsInfohAction => (
  { type: 'SET_VO_STUDENT_JOURNALS_CLEAR_INFO' }
)

type KnownAction = SetJournalsInfoAction | SetClearJournalsInfohAction;

export const reducer: Reducer<VoStudentJournalsInfoState> = (state: VoStudentJournalsInfoState | undefined, incomingAction: Action): VoStudentJournalsInfoState => {

  if (state === undefined) {
    return { studentJournalsInfo: { startDate: null, endDate: null, selectedAll: false, selectedIds: [], areaId: undefined } };
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'SET_VO_STUDENT_JOURNALS_INFO':
      return { studentJournalsInfo: action.info };
    case 'SET_VO_STUDENT_JOURNALS_CLEAR_INFO':
      return { studentJournalsInfo: { startDate: null, endDate: null, selectedAll: false, selectedIds: [], areaId: undefined } };
    default:
      return state;
  }

};