import { AppThunkAction } from './index'
import { IComponentStatus } from '../Interface/MainTypes';
import { SetHttpErrorMessages, SetHttpErrorMessagesAction } from './HttpErrorMessages';
import { SetHttpWarningMessages, SetHttpWarningMessagesAction } from './HttpWarningMessages';
import { v4 as uuidv4 } from 'uuid';
import { ISkrinResponseMessages } from '../Interface/MainTypes';

enum ISkrinResponseStatus { Success = 0, Error = 1, Warning = 2 }

interface ISkrinResponse<T> {
  data: T,
  status: ISkrinResponseStatus,
  errorMessages: Array<string>,
  warningMessages: Array<string>,
}

type HttpMethod = 'GET' | 'POST' | 'DELETE' | 'PUT';

export type SetStatusRequestType = (status: IComponentStatus) => void;

function GetRequestData<U = undefined>(url: string, method: HttpMethod, body?: U): Request {

  const baseUrl = window.location.origin;
  const fullPath = `${baseUrl}/${url}`;

  if (body) {
    return new Request(fullPath, {
      method: method,
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    });
  }

  return new Request(fullPath, { method: method });
}


export const SkrinHttpRequest = <T, U = undefined>(
  url: string,
  method: HttpMethod,
  setStatusRequest?: SetStatusRequestType,
  body?: U,
): AppThunkAction<Promise<T | undefined> | SetHttpWarningMessagesAction | SetHttpErrorMessagesAction> => async (dispatch) => {

  if (setStatusRequest)
    setStatusRequest('pending');

  let responseStatus: number | undefined = undefined;
  let responseStatusText: string | undefined = undefined;

  const request = GetRequestData<U>(url, method, body);
  try {
    const response = await fetch(request);

    responseStatus = response.status;
    responseStatusText = response.statusText;

    if (false === response.ok) {
      throw new Error("Failed_request");
    }

      const contentType: string | null = response.headers.get('content-type');
      const isJsonResponse: boolean = IsJsonResponse(contentType);

      if (setStatusRequest)
          setStatusRequest('success');

      if (false === isJsonResponse)
          return undefined;

    const responseData: ISkrinResponse<T> = await response.json();

    if (setStatusRequest)
      setStatusRequest('success');

    if (responseData.status === ISkrinResponseStatus.Success) {
      return responseData.data;
    } else if (responseData.status === ISkrinResponseStatus.Error) {
      const errorMessages: ISkrinResponseMessages = { id: uuidv4().toString(), messages: responseData.errorMessages };
      dispatch(SetHttpErrorMessages(errorMessages));
    } else if (responseData.status === ISkrinResponseStatus.Warning) {
      const warningMessages: ISkrinResponseMessages = { id: uuidv4().toString(), messages: responseData.warningMessages };
      dispatch(SetHttpWarningMessages(warningMessages));
    }

    return undefined;
  }
  catch (error) {
    const message = error instanceof Error ? error.message : String(error);
    const stacktrace = error instanceof Error && error.stack ? error.stack : '';
    const bodyJson = body ? JSON.stringify(body) : undefined;
    const msg = MessageFormatHttp(request.url, responseStatus, responseStatusText, message, stacktrace, bodyJson, request.method);
    console.log(msg);
    throw new Error(msg);
  }
}

function IsJsonResponse(contentTypeHeader: string | null): boolean {
    if (contentTypeHeader === null)
        return false;

    if (contentTypeHeader && contentTypeHeader.length === 0)
        return false;

    if (contentTypeHeader && contentTypeHeader.toLowerCase().includes("application/json"))
        return true;

    return false;
}

function MessageFormatHttp(fullPath: string, responseStatus: number | undefined, responseStatusText: string | undefined, message: string, stacktrace: string, body?: string | undefined, method?: string | undefined): string {
  const methodLabel: string = `method = ${method};`;
  const urlLabel: string = `url = ${fullPath};`;
  const responseStatusLabel: string = `responseStatus = ${responseStatus};`;
  const responseStatusTextLabel: string = `responseStatusText = ${responseStatusText};`;
  const bodyLabel: string = `body = ${body};`;
  const messageLabel: string = `message = ${message};`;
  const stacktraceLabel: string = `innerStack = ${stacktrace};`;

  return `HttpRequestError ${methodLabel} ${urlLabel} ${responseStatusLabel} ${responseStatusTextLabel} ${bodyLabel} ${messageLabel} ${stacktraceLabel}`;
}


export default SkrinHttpRequest;