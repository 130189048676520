
import Grid from '@material-ui/core/Grid';
import { Alert, AlertTitle } from '@material-ui/lab';
import Loader from '../Loader/Loader';
import { ApplicationState } from '../../../store/index';
import { Redirect } from 'react-router-dom';
import { StartPagePath } from '../../../Interface/RouteConst';
import { connect, ConnectedProps } from 'react-redux';
import { IComponentStatus } from './../../../Interface/MainTypes';
import { useEffect, useState } from 'react';
import { get } from './../../../Utils/Fetch';
import { apiImportDataGetFullPathFileDb } from './../../../Interface/ServerRouteConst';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Snackbar from '@material-ui/core/Snackbar';
import AlertIconOverride from './../AlertIconOverride/AlertIconOverride';


const mapStateToProps = (state: ApplicationState) => ({
  dbUpdateProcessHasError: state.appAuth.appAuth.dbUpdateProcessHasError
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;


const DbUpdateHasError = (props: PropsFromRedux) => {
  const [status, setStatus] = useState<IComponentStatus>('idle');
  const [fullPathFile, setFullPathFile] = useState<string>('');
  const [showSnack, setShowSnack] = useState<boolean>(false);

  useEffect(() => {
    setStatus('pending');

    get<string>(apiImportDataGetFullPathFileDb)
      .then((responseFolder: string) => {
        setFullPathFile(responseFolder);
        setStatus('success');
      })

  }, []);

  const handelCloseSnack = () => setShowSnack(false);

  const copyPath = () => {
    if (fullPathFile.length > 0) {
      navigator.clipboard.writeText(fullPathFile);
      setShowSnack(true);
    }
  }

  if (props.dbUpdateProcessHasError === false)
    return <Redirect to={StartPagePath} />

  if (status === 'pending') {
    return (
      <Grid container spacing={0} justifyContent="center">
        <Grid item={true} lg={8} md={12} xs={12} sm={12} style={{ textAlign: "center" }}>
          {Loader}
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container direction='row' spacing={2} justifyContent="center">
      <Grid item={true} lg={8} md={12} xs={12} sm={12} >
        <Alert severity="error"><AlertTitle> <strong>Ошибка обновления базы данных</strong> </AlertTitle>
          <p>Для восстановления работы приложения требуется :</p>
          <ol>
            <li>Замена файла БД резервной копией</li>
            <li>Перезапуск службы SkrinDesktop</li>
          </ol>
        </Alert>
      </Grid>
      <Grid item={true} lg={8} md={12} xs={12} sm={12} >
        <div style={{ display: "flex", justifyContent: "center" }} >
          {
            (fullPathFile.length > 0) &&
            (
              <TextField
                label="Полный путь к файлу БД"
                fullWidth
                value={fullPathFile}
                autoComplete="off"
                InputProps={{
                  readOnly: true,
                  startAdornment: (<IconButton onClick={copyPath} size='small' title='Копировать' >
                    <FileCopyIcon fontSize='small' />
                  </IconButton>)
                }}
              />
            )
          }
        </div>
      </Grid>
      <Grid item={true} lg={8} md={12} xs={12} sm={12} >
        <div style={{ display: "flex", justifyContent: "center" }} >
          {Loader}
        </div>
      </Grid>
      <Snackbar
        open={showSnack}
        autoHideDuration={6000}
        onClose={handelCloseSnack}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <AlertIconOverride onClose={handelCloseSnack} severity="success" variant="filled">
          Путь скопирован в буфер
        </AlertIconOverride>
      </Snackbar>
    </Grid>
  );
}

export default connector(DbUpdateHasError);