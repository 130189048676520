import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect, ConnectedProps} from 'react-redux';
import { ResetApp } from './../../../store/Error';


const mapDispatchToProps = (dispatch : any) =>{
  return {
    unSetError : () => dispatch(ResetApp())
  }
}

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;


const ErrorView = (props : PropsFromRedux) =>{

  
  
  const onResetApp = (e : React.MouseEvent<HTMLButtonElement>) =>{
    props.unSetError();
  }

  return(
    <Grid container spacing={0} justifyContent="center">
      <Grid item={true} lg={8}  md={12}  xs={12} sm={12} style = { { textAlign : "center" } }>
          <h3>Произошла ошибка</h3>
          <Button 
              variant="contained" 
              color="primary" 
              size="small"
              onClick = { onResetApp }
              >
              Перейти на главную страницу 
          </Button>
      </Grid>
    </Grid>
  );
}

export default connector(ErrorView);