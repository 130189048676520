import buildDate from "./../../../buildDate.json";
import { get } from "./../../../Utils/Fetch";
import { useState, useEffect } from 'react';
import { IBuildDate } from "./../../../Interface/MainTypes";
import { Alert } from '@material-ui/lab';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

const ClearCacheComponent = () => {
  const [intervalId, setIntervalId] = useState<null | NodeJS.Timeout>(null);
  const [showQuery, setShowQuery] = useState<boolean>(false);

  const onReload = () => {
    window.location.reload();
  }

  const ClearTimer = () => {
    if (intervalId)
      clearInterval(intervalId);
  }

  useEffect(() => {

    let intervalID = setInterval(() => {

      const timeLabel = new Date().getTime();
      const url : string = `meta.json?v${timeLabel}`;

      get<IBuildDate>(url)
        .then((response: IBuildDate) => {
          if ((showQuery === false) && (response.buildDate !== buildDate.buildDate)) {
            setShowQuery(true);
          }
        })
        .catch(error => {
          console.log("Error status chank", error);
        })

    }, 180000); // 3 мин. 180000

    setIntervalId(intervalID);

    return () => ClearTimer();
  }, [])

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowQuery(false);
  };

  return (
    <Snackbar open={showQuery} onClose={handleClose} style={{ zIndex : 500 }} >
      <Alert severity="info" variant="filled"
        action={
          <>
            <IconButton onClick={onReload} size="small" title="Перезагрузить" >
              <RefreshIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={handleClose} size="small" title="Закрыть" >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      >
        Сайт был обновлен. Чтобы избежать ошибок, рекомендуем перезагрузить страницу
      </Alert>
    </Snackbar>
  )

}

export default ClearCacheComponent;