import { Action, Reducer } from 'redux';
import { IKaFlAuthState } from './../Interface/KaFlTypes';

export interface KaFlAuthState {
  kaFlAuth: IKaFlAuthState
}

export interface SetAuthAction    { type: 'SET_KAFL_AUTH', auth: IKaFlAuthState }
export interface SetLogOutAction  { type: 'SET_KAFL_AUTH_LOGOUT'}

export const SetKalAuth = (auth: IKaFlAuthState): SetAuthAction => (
  { type: 'SET_KAFL_AUTH', auth }
)

export const SetKaFlLogOut = (): SetLogOutAction => (
  { type: 'SET_KAFL_AUTH_LOGOUT' }
)

type KnownAction = SetAuthAction | SetLogOutAction;

const defaultState : IKaFlAuthState = { isAuth : false, login : null, error : null, accesses : null };


export const reducer: Reducer<KaFlAuthState> = (state: KaFlAuthState | undefined, incomingAction: Action): KaFlAuthState => {

  if (state === undefined) {
    return { kaFlAuth: defaultState };
  }
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'SET_KAFL_AUTH': {
      return { kaFlAuth: action.auth };
    }
    case 'SET_KAFL_AUTH_LOGOUT' : {
      return { kaFlAuth: defaultState };
    }
    default:
      return state;
  }
};