
export type IComponentStatus = 'idle' | 'pending' | 'success' | 'error';

export type ISite = 'vo' | 'soap' | 'kafl' | 'podftfl';


export interface IAuth {
  login: string,
  password: string
}

export interface IAuthCommon {
  login: string,
  password: string,
  targetModule : ISiteList
}


// карточка вывода на сайте 
export interface ICard {
  id: number,
  color: string,
  url: string,
  title: string
}

export type IStateType = 'Desktop' | 'Web';

export interface IAuthStatus {
  isAuth: boolean,
  isRun: boolean,
  hasUpdate: boolean,
  updateProcess: boolean,
  blockDbChanges: boolean,
  dbUpdateProcessHasError: boolean,
  hasLongScriptDbUpdate : boolean,
  errorMessage: string | null,
  modules: Array<IModules> | null,
  stateType: IStateType,
}

export interface ISiteTheme {
  id: number,
  name: string
}

export interface IModules {
  id: number,
  name: string,
  limit: number,
  siteTheme: ISiteTheme,
  accessTypes: Array<IAccessTypes>
}

export interface IAccessTypes {
  id: number,
  name: string,
  limit: number,
  beginDate: Date | null,
  endDate: Date | null,
}


export type IFormMode = "editMode" | "createMode";

export type IModalType = "error" | "warning" | "info" | "success";


export class ClientError {
  name: string;
  message: string;
  stack?: string;
  constructor(name: string, message: string, stack?: string) {
    this.name = name;
    this.message = message;
    this.stack = stack;
  }
}

// API = 14,
// VO = 13,


export interface IExtendedModules {
  id: number
  show: boolean,
  title: string
}

export interface IUserInfo {
  userLogin: string | null,
  version: string | null,
  modules: Array<IModules> | null
}

/// тип возврата содержащий ошибки от сервера 
export interface IResponseData {
  status: boolean,
  errorMessage: string
}

export interface IResponseErorWarningMessage {
  status: boolean,
  hasWarning: boolean,
  errorMessage: string | null,
  warningMessage: string | null
}

export interface IResponseWarningData {
  status: boolean,
  errorMessage: Array<string> | null,
  warningMessage: Array<string> | null
}

export interface IResponseSuccessDate {
  status: boolean,
  errorMessage: string,
  successMessage: string
}

export interface PagesData<T> {
  currentPage: number,
  countPage: number,
  pageSize: number,
  items: Array<T>
}

//пагинаци с указанием totalCount 
export interface PagesDataSize<T> {
  currentPage: number,
  countPage: number,
  pageSize: number,
  totalCount: number,
  items: Array<T>
}

/** Пагинация из Common с TotalCount */
export interface PagesDataCommon<T> {
    /** тек. страница*/
    currentPage: number,
    
    /** число страниц  */
    countPage: number,
    
    /** размер страницы  */
    pageSize: number,
    
    /** общее количество объектов */
    totalItemCount: number,
    
    /** список элементов   */
    items: Array<T>
}

export type ITextFieldVariants = "filled" | "outlined" | "standard";

export type ITextFieldSizes = "medium" | "small";

export enum ISiteList { api = 14, voWeb = 13, voDesktop = 15, kaFl = 16, podftFl = 5 } // список сайтов 

export const ISiteTypeDesktopDictionary : Record<ISite, ISiteList> = {
  vo : ISiteList.voDesktop,
  soap : ISiteList.api,
  kafl : ISiteList.kaFl,
  podftfl : ISiteList.podftFl
}

export type ISortingOrder = 'asc' | 'desc' | 'none';

export interface IVoEmailSendResult {
  smtpStatus: boolean,
  countSucces: number,
  emailsFailedSend: Array<string>
}

export interface IAdminActionResult {
  hasAccess: boolean,
  status: boolean
}

export interface IBuildDate {
  buildDate: number
}

export enum IPeriodCron { none = 0, onceDay = 1, onceWeek = 2, onceMonth = 3 }
export enum IPeriodDayOfWeek { sunday = 0, monday = 1, tuesday = 2, wednesday = 3, thursday = 4, friday = 5, saturday = 6 }

export interface IAutoBackupConfig {
  folder: string,
  status: boolean,
  afterDayesCount: number,
  dateTimeBackup: Date | null,
  timeSpanBackup: string,

  // новые поля 
  period: IPeriodCron,
  periodDayOfWeek: IPeriodDayOfWeek,
  periodDayOfMonth: number
}

export interface ISiteTitleFavicon {
  title: string,
  faviconLink: string
}

export interface ISkrinResponseMessages
{
  id : string,
  messages: Array<string>
}

export interface ISearchStringPaging {
	searchString : string, 
	page : number,
	pageSize : number,
}

export interface ISelectOptions {
  key : string ,
  value : string,
  name : string
}