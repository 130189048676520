
/* клиент */
export const StartPagePath : string = "/";
export const MainAuthPath : string = "/main/auth";
export const StartUpdateInfoPath : string = "/main/startupdate";
export const StartUpdateDbInfoPath : string = "/main/startdbupdate";

export const UserInfoPath : string = "/main/userinfo";
export const CommonSettingPath : string = "/main/commonsetting";
export const UserSettingLogs : string = "/main/settingLogs";
export const DataImportPath : string = "/main/dataImport";
export const CreateDbBackUpPagePath : string = "/main/createdbbackuppage";
export const TestSkrinHttpRequestPath : string = "/main/testSkrinRequest";



export const VoRoutePath  :       string = "/vo";

export const VoAuthPath   :                 string = "/vo/auth";
export const VoSettingSdlPath :             string = "/vo/setting/sdl";
export const VoSettingSdlAddPath :          string = "/vo/setting/sdl/create";
export const VoSettingSdlEditRoute :        string = "/vo/setting/sdl/edit/:id";
export const VoSettingSdlEditPath :         string = "/vo/setting/sdl/edit/"; 
export const VoSettingAreasPath :           string = "/vo/setting/areas";


export const VoAdminSettingSmtpPath : string = "/vo/adminsetting/smtp"


export const VoSettingAreasAddRoute :       string = "/vo/setting/areas/create/:typeParam";
export const VoSettingAreasAddPath :        string = "/vo/setting/areas/create/";

export const VoSettingAreasEditRoute :      string = "/vo/setting/areas/edit/:id";
export const VoSettingAreasEditPath :       string = "/vo/setting/areas/edit/"

export const VoSettingStructCompany :       string = "/vo/setting/struct";

export const VoSetUserPassword :  string = "/vo/setpassword/:key";

// вопросная база 

export const VoQuestionBasePath : string = "/vo/questionbase";
export const VoQuestionBaseRoute : string = "/vo/questionbase/:mode/:page?";

export const VoQuestionBaseListPath : string = "/vo/questionbase/show";
export const VoQuestionBaseListRoute : string = "/vo/questionbase/show/:page?";

export const VoQuestionBaseCreatePath : string = "/vo/questionbase/create";

export const VoQuestionBaseEditPath :   string = "/vo/questionbase/edit/";
export const VoQuestionBaseEditRoute : string = "/vo/questionbase/edit/:id";

export const VoQuestionBaseCopyPath : string = "/vo/questionbase/copy/";
export const VoQuestionBaseCopyRoute : string = "/vo/questionbase/copy/:id";

// табы новостей 

export const VoNewsPath :           string = "/vo/news";
export const VoNewsTabPath :        string = "/vo/news/tab";
export const VoNewsTabRoute :        string = "/vo/news/tab/:tabId/:mode/:page?";

//новости СКРИН вывод списка 
export const VoSkrinNewsAdminShowListPath:    string = "/vo/news/tab/0/show/";
export const VoSkrinNewsAdminShowListRoute :  string = "/vo/news/tab/0/show/:page?";

export const VoSkrinNewsAdminOpenItemPath :  string = "/vo/news/tab/0/open/";
export const VoSkrinNewsAdminOpenItemRoute : string = "/vo/news/tab/0/open/:id";

//новости СДЛ 
export const VoSDLNewsAdminShowListPath:    string = "/vo/news/tab/1/show/";
export const VoSDLNewsAdminShowListRoute :  string = "/vo/news/tab/1/show/:page?";

export const VoSDLNewsAdminOpenItemPath :  string = "/vo/news/tab/1/open/";
export const VoSDLNewsAdminOpenItemRoute : string = "/vo/news/tab/1/open/:id";

export const VoSDLNewsAdminCreatePath :  string = "/vo/news/tab/1/create";

export const VoSDLNewsAdminEditPath : string = "/vo/news/tab/1/edit/";
export const VoSDLNewsAdminEditRoute : string = "/vo/news/tab/1/edit/:id";


// табы документов 
export const VoDocumentsPath :     string = "/vo/documents";
export const VoDocumentsTabPath :  string = "/vo/documents/tab";
export const VoDocumentsTabRoute : string = "/vo/documents/tab/:tabId/:page";

//таб видео video
export const VoVideoClipsPath :     string = "/vo/video";
export const VoVideoClipsTabPath :  string = "/vo/video/tab";
export const VoVideoClipsTabRoute : string = "/vo/video/tab/:tabId/:page";




//студенты

export const VoStudetsTabPath :    string =   "/vo/students/tab";
export const VoStudetsTabRoute :   string =   "/vo/students/tab/:tabId/:mode/:page?";


export const VoStudentTabShowList : string =  "/vo/students/tab/0/show/:page?";
export const VoStudetAddPath :  string =      "/vo/students/tab/0/create";

export const VoStudetEditRoute : string = "/vo/students/tab/0/edit/:id";
export const VoStudetEditPath :  string = "/vo/students/tab/0/edit/";

//журнал нескольких студентов 
export const VoStudentsJournalsRoute : string = "/vo/students/tab/0/journals/:page";
export const VoStudentsJournalsPath : string = "/vo/students/tab/0/journals";

// загрузка студентов из файла 
export const VoStudentLoadFile : string = "/vo/students/tab/0/loadlist";

// история активности студента
export const VoTrainingStudentHistoryRoute : string = "/vo/students/tab/0/studenthistory/:id/:page";
export const VoTrainingStudentHistoryPath : string =  "/vo/students/tab/0/studenthistory/";

// жкрнал 1 студента
export const VoStudentJournalRoute : string = "/vo/students/tab/0/journal/:id/:page";
export const VoStudentJournalPath : string = "/vo/students/tab/0/journal";

// сертификаты 1 студента
export const VoStudentCertPath : string = "/vo/students/tab/0/certs";
export const VoStudentCertRoute : string = "/vo/students/tab/0/certs/:id/:page";


// обучение 
export const VoTrainingPath   : string =  "/vo/training";
export const VoTrainingRoute  : string = "/vo/training/:mode/:id?";

export const VoTrainingShowListPath : string = "/vo/training/show";
export const VoTrainingShowListRoute : string = "/vo/training/show/:page?";

export const VoTrainingAddPath :  string =  "/vo/training/create";

export const VoTrainingOpenRoute : string =   "/vo/training/open/:id";
export const VoTrainingOpenPath :  string =   "/vo/training/open/";

export const VoTrainingEditRoute : string =   "/vo/training/edit/:id";
export const VoTrainingEditPath :  string =   "/vo/training/edit/";

export const VoTrainingCopyRoute : string =   "/vo/training/copy/:id";
export const VoTrainingCopyPath :  string =   "/vo/training/copy/";

export const VoTrainingProgressRoute : string = "/vo/training/progress/:id/:page";
export const VoTrainingProgressPath : string = "/vo/training/progress/";

export const VoTrainingJournalRoute : string = "/vo/training/journal/:id/:page";
export const VoTrainingJournalPath : string = "/vo/training/journal/";

export const VoUserManualPath : string = "/vo/usermanual";

// раздел роли студента 
//новости 1 компонет
export const VoStudentNewsTabs  :       string =  "/vo/student/news/tab";
export const VoStudentNewsTabRoute :    string =  "/vo/student/news/tab/:tabId/:mode/:page?";

export const VoStudentSkrinNewsShowListPath : string =  "/vo/student/news/tab/0/show/";
export const VoStudentSkrinNewsShowListRoute : string = "/vo/student/news/tab/0/show/:page?";

export const VoStudentSkrinNewsOpenItemPath : string =  "/vo/student/news/tab/0/open/";
export const VoStudentSkrinNewsOpenItemRoute : string = "/vo/student/news/tab/0/open/:id?";

export const VoStudentSdlNewsShowListPath : string =  "/vo/student/news/tab/1/show/";
export const VoStudentSdlNewsShowListRoute : string = "/vo/student/news/tab/1/show/:page?";

export const VoStudentSdlNewsOpenItemPath : string =  "/vo/student/news/tab/1/open/";
export const VoStudentSdlNewsOpenItemRoute : string = "/vo/student/news/tab/1/open/:id?";


// студенты обучение 

export const VoStudentTrainingsPath :  string =  "/vo/student/training";
export const VoStudentTrainingsRoute : string = "/vo/student/training/:mode/:id?";

export const VoStudentTrainingShowPath :  string =  "/vo/student/training/show";
export const VoStudentTrainingShowRoute : string = "/vo/student/training/show/:page?";

export const VoStudentOpenTrainingPath :  string =   "/vo/student/training/open/";
export const VoStudentOpenTrainingRoute : string =   "/vo/student/training/open/:id";

export const VoStudentOpenTrainingTabsRoute : string =   "/vo/student/training/open/:id/tabs/:tabId";


export const VoStudentOpenTestPath : string =   "/vo/student/training/open/";
export const VoStudentOpenTestRoute : string =  "/vo/student/training/open/:traingingId/tabs/3/testInfo/:id/:materialtype";


export const VoStudentOpenQuestionPath :   string = "/vo/student/training/open/";
export const VoStudentOpenQuestionRoute :  string = "/vo/student/training/open/:traingingId/tabs/3/openquestion/:execId";

export const VoStudentOpenTestResultPath : string = "/vo/student/training/open/";
export const VoStudentOpenTestResultRoute : string = "/vo/student/training/open/:traingingId/tabs/3/testresult/:execId";

export const VoPricePath :           string = "/vo/price";

export const ApiRoutePath  :      string = "/soap";
export const ApiRegistryPath :    string = "/soap/registry";
export const KaFlRoutePath  :     string = "/kafl";
export const PodftFlRoutePath :    string = "/podftfl";

