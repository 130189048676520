import { Action, Reducer } from 'redux';
import {AppThunkAction} from './index'
import { push } from 'connected-react-router'
import { StartPagePath } from './../Interface/RouteConst';
import { ClientError } from './../Interface/MainTypes'

export interface ErrorState {
    error: ClientError | null
}

export interface SetErrorAction   { type: 'SET_ERROR',  error : ClientError }
export interface UnSetErrorAction { type: 'UNSET_ERROR' }


type KnownAction = SetErrorAction | UnSetErrorAction;


export const setError = (error : Error) : SetErrorAction => (
   { type: 'SET_ERROR', error }
)

const unSetError = () : UnSetErrorAction => (
    { type: 'UNSET_ERROR' }
)

export const ResetApp = (): AppThunkAction<any> => (dispatch, getState) => {
    const appState = getState();
    if (appState) {
        dispatch(unSetError());
        dispatch(push(StartPagePath));
    }
}

export const reducer: Reducer<ErrorState> = (state: ErrorState | undefined, incomingAction: Action): ErrorState => {
    
    if (state === undefined) {
        return { error : null };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_ERROR':
                return {error : new ClientError(action.error.message, action.error.name, action.error.stack) };
        case 'UNSET_ERROR':
            return { ...state, error: null };
        default:
            return state;
    }
};

export const mapDispatchToPropsError = (dispatch : any) =>{
    return {
        setError :(error: Error) => dispatch(setError(error)),
    }
  }