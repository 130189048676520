import { useState, useEffect } from 'react';
import { ApplicationState } from './../../../store/index';
import { connect, ConnectedProps } from 'react-redux';
import { Alert } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useHistory } from 'react-router-dom';
import { StartUpdateDbInfoPath, StartUpdateInfoPath } from './../../../Interface/RouteConst';

const mapStateToProps = (state: ApplicationState) => ({
  appHasUpdate: state.appAuth.appAuth.hasUpdate,
  hasLongScriptDbUpdate: state.appAuth.appAuth.hasLongScriptDbUpdate
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;


const stepSizeTimer: number = 10000; // 10c. вызов таймера 
const stepSizeCount: number = 60;    // 10 мин. после закрытия 

const UpdateAppAlert = (props: PropsFromRedux) => {
  const [open, setOpen] = useState<boolean>(false);//useState<boolean>(false);
  const [timer, setTimer] = useState<number>(stepSizeCount);

  let history = useHistory();

  useEffect(() => {
    if (props.appHasUpdate || props.hasLongScriptDbUpdate) {
      setOpen(true);
    }
  }, [props.appHasUpdate, props.hasLongScriptDbUpdate]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if ((open === false) && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, stepSizeTimer);
    }

    const needShowFromRedux: boolean = props.appHasUpdate || props.hasLongScriptDbUpdate;

    if ((0 === timer) && (false === open) && needShowFromRedux)
      setOpen(true);
    

    return () => {
      clearInterval(interval);
    };
  }, [open, timer]);


  const handleSnackbarClose = () => {
    setOpen(false);
    setTimer(stepSizeCount);
  };

  const getTitleMessage = (): string => {
    if (props.appHasUpdate)
      return "Доступно обновление";

    if (props.hasLongScriptDbUpdate)
      return "Требуется обновление БД";

    return "";
  }

  const handleUpdateClick = () => {
    if (props.appHasUpdate) {
      setOpen(false);
      history.push(StartUpdateInfoPath);
      return;
    }

    if (props.hasLongScriptDbUpdate) {
      setOpen(false);
      history.push(StartUpdateDbInfoPath);
      return;
    }
  };


  return (
    <Snackbar
      open={open} onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      style={{ width: '100%', maxWidth: '500px', zIndex: 1000 }}
    >
      <Alert severity="warning" variant="standard"
        style={{ width: '100%', border: '1px solid black' }}
        action={
          <>
            <IconButton size="small" title="Обновить" onClick={handleUpdateClick} >
              <ArrowForwardIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" title="Закрыть" onClick={handleSnackbarClose} >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      >{getTitleMessage()}</Alert>
    </Snackbar>
  )
}

export default connector(UpdateAppAlert);