import { Action, Reducer } from 'redux';
import { IAuthStatus, IModules } from './../Interface/MainTypes'
import { AppThunkAction } from './index'
import { IsEqual } from './../Utils/IsEqualFun';

export interface AuthStatusState {
  appAuth: IAuthStatus
}

export interface SetAuthAction { type: 'SET_AUTH', auth: IAuthStatus }
export interface SetStartUpdateAction { type: 'SET_START_UPDATE' }
export interface SetEndUpdateAction { type: 'SET_END_UPDATE' }
export interface SetAuthModulesAction { type: 'SET_AUTH_MODULES', modules: Array<IModules> | null }

export const SetAuth = (auth: IAuthStatus): SetAuthAction => (
  { type: 'SET_AUTH', auth }
)

export const SetStartUpdate = (): SetStartUpdateAction => (
  { type: 'SET_START_UPDATE' }
)

export const SetEndUpdate = (): SetEndUpdateAction => (
  { type: 'SET_END_UPDATE' }
)

export const SetAuthModules = (modules: Array<IModules> | null): SetAuthModulesAction => (
  { type: 'SET_AUTH_MODULES', modules }
)

export const UpdateAppStateToTimer = (serverState: IAuthStatus): AppThunkAction<any> => (dispatch, getState) => {

  let clientState: IAuthStatus = getState().appAuth.appAuth;
  let statusState: boolean = IsEqual(clientState, serverState);

  if (false === statusState) {
    let clientStatusUpdate: boolean = getState().appAuth.appAuth.updateProcess;
    let serverStatusUpdate: boolean = serverState.updateProcess;

    if (clientStatusUpdate === serverStatusUpdate) {
      dispatch(SetAuth(serverState));
    }
    else {
      dispatch(SetEndUpdateStatus());
      window.location.reload(); // обновление программы
    }
  }
}

export const SetStartUpdateStatus = (): AppThunkAction<any> => (dispatch, getState) => {
  let isStartUpdate: boolean = getState().appAuth.appAuth.updateProcess;

  if (false === isStartUpdate) {
    dispatch(SetStartUpdate());
  }
}

export const SetEndUpdateStatus = (): AppThunkAction<any> => (dispatch, getState) => {
  let isStartUpdate: boolean = getState().appAuth.appAuth.updateProcess;

  if (isStartUpdate) {
    dispatch(SetEndUpdate());
  }
}


type KnownAction = SetAuthAction | SetStartUpdateAction | SetAuthModulesAction | SetEndUpdateAction;

export const reducer: Reducer<AuthStatusState> = (state: AuthStatusState | undefined, incomingAction: Action): AuthStatusState => {

  if (state === undefined) {
      return { appAuth : { isAuth : false, isRun : false, updateProcess : false, hasUpdate : false, blockDbChanges : false, dbUpdateProcessHasError : false ,errorMessage : null, modules : null, stateType : 'Desktop', hasLongScriptDbUpdate : false} };
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'SET_AUTH':
      return { appAuth: action.auth };
    case 'SET_START_UPDATE':
      {
        let actualData: IAuthStatus = { ...state.appAuth };
        actualData.updateProcess = true;
        return { appAuth: actualData };
      }
    case 'SET_END_UPDATE': {
      let actualData: IAuthStatus = { ...state.appAuth };
      actualData.updateProcess = false;
      return { appAuth: actualData };
    }
    case 'SET_AUTH_MODULES':
      {
        let actualData: IAuthStatus = { ...state.appAuth };
        actualData.modules = action.modules;
        return { appAuth: actualData };
      }
    default:
      return state;
  }
};




