import { Action, Reducer } from 'redux';
import { IVoAuthState, IVoUserArea } from './../Interface/VoTypes';
import { AppThunkAction } from './index'
import { getCookie, setCookie } from 'typescript-cookie'

export interface VoAuthState {
  voAuth: IVoAuthState
}

export interface SetAuthAction { type: 'SET_VO_AUTH', auth: IVoAuthState }
export interface SetActiveUserArea { type: 'SET_VO_AREA_SET_ACTIVE', activeArea: IVoUserArea | null }
export interface SetUserAreas { type: 'SET_VO_AREA_SET_LIST', userAreas: Array<IVoUserArea> | null }

export const SetVoAuth = (auth: IVoAuthState): SetAuthAction => (
  { type: 'SET_VO_AUTH', auth }
)

export const SetActiveArea = (activeArea: IVoUserArea | null): SetActiveUserArea => (
  { type: 'SET_VO_AREA_SET_ACTIVE', activeArea }
)

export const SetAreaList = (userAreas: Array<IVoUserArea> | null): SetUserAreas => (
  { type: 'SET_VO_AREA_SET_LIST', userAreas }
)

export const UpdateAreaList = (userAreas: Array<IVoUserArea> | null): AppThunkAction<any> => (dispatch, getState) => {
  const activeArea: IVoUserArea | null = getState().voAuth.voAuth.activeUserArea;

  if (activeArea === null) {
    dispatch(SetAreaList(userAreas));
    return;
  }

  const activeAreaInList: IVoUserArea | undefined = userAreas?.find(x => x.id === activeArea.id);

  if ((!activeAreaInList) || (activeAreaInList.isEnable === false)) {
    dispatch(SetActiveArea(null));
    dispatch(SetAreaList(userAreas));
    return;
  }

  if (activeAreaInList.isEnable) {
    dispatch(SetAreaList(userAreas));
    return;
  }
}



type KnownAction = SetAuthAction | SetActiveUserArea | SetUserAreas;
const nameActiveAreaId : string = "_actAreaId";


export const reducer: Reducer<VoAuthState> = (state: VoAuthState | undefined, incomingAction: Action): VoAuthState => {

  if (state === undefined) {
    return { voAuth: { isAuth: false, userLogin: null, role: null, errorMessage: null, userAreas: null, activeUserArea: null } };
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'SET_VO_AUTH': {
      const oldSelctAreaId : string | undefined = getCookie(nameActiveAreaId);

      if(!oldSelctAreaId)
        return { voAuth: action.auth };

      const item : IVoUserArea | undefined = action.auth.userAreas?.find(x => x.id === Number(oldSelctAreaId) && x.isEnable === true);

      if(item)
      {
        const newState = {...action.auth, activeUserArea : item };
        return { voAuth: newState };
      }

      return { voAuth: action.auth };
    }
    case 'SET_VO_AREA_SET_ACTIVE':
      {
        let actualData: IVoAuthState = { ...state.voAuth };
        actualData.activeUserArea = action.activeArea;
        
        if(action.activeArea)
          setCookie(nameActiveAreaId, action.activeArea.id, { expires: 7, path : "/" } );
        
          return { voAuth: actualData };
      }
    case 'SET_VO_AREA_SET_LIST':
      {
        let actulState: IVoAuthState = { ...state.voAuth };
        actulState.userAreas = action.userAreas;
        return { voAuth: actulState };
      }
    default:
      return state;
  }
};



