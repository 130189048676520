
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import ErrorBoundary from './Components/Common/ErrrorView/ErrorBoundary';
import RequestStatus from './RequestStatus';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const history = createBrowserHistory({ basename: baseUrl });
const store = configureStore(history);


(window as any).ReduxStore = store; // для отображения ошибки 

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <ErrorBoundary>
                <App />
                <RequestStatus/>
            </ErrorBoundary> 
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'));
