import Grid from '@material-ui/core/Grid';

/** компонент общей обертки по ширине + paddind 1% общий  */

const WrapperPaddingWidth : React.FC = ({ children }) => {
  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item={true} lg={8} md={12} xs={12} sm={12} style={{ padding: "1%" }} >
        {children}
      </Grid>
    </Grid>
  )
}

export default WrapperPaddingWidth;