import Grid from '@material-ui/core/Grid';
import Loader from './../../Common/Loader/Loader';
import { useState } from 'react';
import { IComponentStatus, IAuthStatus, IAdminActionResult } from '../../../Interface/MainTypes';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { get } from './../../../Utils/Fetch';
import { apiStartDbUpdate, apiMainAuthAuto } from './../../../Interface/ServerRouteConst';
import { SetAuth } from './../../../store/AuthStatus';
import { ApplicationState } from './../../../store/index';
import { connect, ConnectedProps } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { StartPagePath } from './../../../Interface/RouteConst';
import { Redirect } from 'react-router-dom';
import ModalMessageWithNode from '../../Common/ModalMessageWithNode/ModalMessageWithNode';
import AccessPage from './../../Main/CommonSetting/AccessPage';
import QueryDialog from './../../Common/QueryDialog/QueryDialog';
import DefaultWrapperWidth from '../../Main/DefaultWrapperWidth/WrapperPaddingWidth';
 

const mapStateToProps = (state: ApplicationState) => ({
  hasLongScriptDbUpdate: state.appAuth.appAuth.hasLongScriptDbUpdate,
});

export const mapDispatchToProps = (dispatch: any) => {
  return {
    setAuth: (auth: IAuthStatus) => dispatch(SetAuth(auth))
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const UpdateDbInfo = (props: PropsFromRedux) => {
  const [status, setStatus] = useState<IComponentStatus>('idle');
  const [openQuryDialog, setOpenQuryDialog] = useState<boolean>(false);
  const [showUserDialog, setShowUserDialog] = useState<boolean>(false);

  const onCloseUserDialog = () => setShowUserDialog(false);
  const onCloseQuryDialog = () => setOpenQuryDialog(false);

  const handelSend = () => setOpenQuryDialog(true);

  const SaveAfterLogin = () => {
    setShowUserDialog(false);
    onSendQuery();
  };

  const onSendQuery = () => {
    setStatus('pending');
    get<IAdminActionResult>(apiStartDbUpdate)
      .then((response: IAdminActionResult) => {
        if (response.hasAccess) {
          get<IAuthStatus>(apiMainAuthAuto)
            .then((response: IAuthStatus) => {
              props.setAuth(response);
              setStatus('success');
            })
        }
        else {
          setStatus('success');
          setShowUserDialog(true);
        }
      })
  }

  if (props.hasLongScriptDbUpdate === false)
    return <Redirect to={StartPagePath} />


  return (
    <DefaultWrapperWidth> 
        <Grid container spacing={0} direction='column' justifyContent="center" alignItems="center" style={{ marginTop: "1%" }}>
          <Grid item={true} lg={8} md={12} xs={12} sm={12} >
            <Alert severity="error">После отправки запроса приложение будет временно недоступно, до завершение обновления БД</Alert>
            <br /><br />
          </Grid>
          <Grid item={true} lg={4} md={4} xs={4} sm={4} >
            {status === 'pending' ? Loader : <Form handelSend={handelSend} />}
          </Grid>
          <ModalMessageWithNode
            title="Подтвердите право доступа"
            show={showUserDialog}
            handleClose={onCloseUserDialog}
            message={<AccessPage handleSuccess={SaveAfterLogin} />}
          />
          <QueryDialog
            open={openQuryDialog}
            onClose={onCloseQuryDialog}
            title="Подтвердите действие"
            textQuery="Вы действительно хотите запустить процесс обновления?"
            onAgree={onSendQuery}
          />
        </Grid>
        </DefaultWrapperWidth>
  );
}

interface IProps {
  handelSend: () => void
}

const Form = (props: IProps) => {
  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={props.handelSend}
      >Запуск процесса обновления</Button>

      <br /> <br />

      <Button
        variant="contained"
        color="primary"
        component={NavLink} to={StartPagePath}
      >Перейти на главную страницу</Button>
    </>
  )
}


export default connector(UpdateDbInfo);

