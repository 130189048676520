import { ApplicationState } from './../../../store/index';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { ISite, ISiteTitleFavicon } from './../../../Interface/MainTypes';
import { VoRoutePath, ApiRoutePath, KaFlRoutePath, PodftFlRoutePath } from "./../../../Interface/RouteConst";
import { ISiteTheme } from './../../../Interface/MainTypes';
import { GetVoTheme } from './../../../Utils/GetVoTheme';


const getActiveSitePage = (location: string): ISite | null => {

  if (location.includes(VoRoutePath)) {
    return "vo";
  }
  else if (location.includes(ApiRoutePath)) {
    return "soap";
  }
  else if (location.includes(PodftFlRoutePath)) {
    return "podftfl";
  }
  else if (location.includes(KaFlRoutePath)) {
    return "kafl"
  }
  else { return null; }
}

const getNewFaviconLink = (value: ISite | null, themeSite: ISiteTheme): ISiteTitleFavicon => {
  switch (value) {
    case "vo":
      if (themeSite.id === 1) {
        return { title: "Метр квадратный Обучение", faviconLink: "/favicons/favicon_vo_m2_blue.ico?v=1" };
      }
      return { title: "СКРИН : Обучение", faviconLink: "/favicons/favicon_vo.ico?v=1" };
    case "soap":
      return { title: "СКРИН : API", faviconLink: "/favicons/favicon_api.ico?v=2" };
    case "podftfl":
        return { title: "СКРИН : ПОДФТ ФЛ", faviconLink: "/favicons/favicon_podft.ico?v=3" };
    case "kafl":
      return { title: "СКРИН : КА/ФЛ", faviconLink: "/favicon.ico?v=0" };
    
    case null:
      return { title: "СКРИН : Desktop", faviconLink: "/favicon.ico?v=0" };
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  stateType: state.appAuth.appAuth.stateType,
  modules: state.appAuth.appAuth.modules,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const DynamicFavicon = (props: PropsFromRedux) => {
  const defaultValue: ISiteTitleFavicon = { title: "СКРИН : Desktop", faviconLink: "/favicon.ico?v=0" };
  const themeSite: ISiteTheme = GetVoTheme(props.modules, props.stateType);
  const [activeSite, setActiveSite] = useState<ISite | null>(null);
  const [faviconLink, setFaviconLink] = useState<ISiteTitleFavicon>(defaultValue);
  const [activeThemeId, setActiveThemeId] = useState<number>(0); // темы по умолчанию id=0

  const activeVoTheme: ISiteTheme = GetVoTheme(props.modules, props.stateType); // тек. момент темы только ВО 
  const location: string = useLocation().pathname;

  useEffect(() => {

    const activeSitePage: ISite | null = getActiveSitePage(location);
    if (false === (activeSitePage === activeSite)) {
      setActiveSite(activeSitePage);
      setFaviconLink(getNewFaviconLink(activeSitePage, themeSite));
    }
  }, [location])

  useEffect(() => { // смена темы id 
    if (false === (activeThemeId === activeVoTheme.id)) {
      setActiveThemeId(activeVoTheme.id);
      const activeSitePage: ISite | null = getActiveSitePage(location);
      setFaviconLink(getNewFaviconLink(activeSitePage, activeVoTheme));
    }
  }, [activeVoTheme.id])


  if (props.stateType === "Web") {
    return (
      <Helmet>
        <link rel="icon" href={faviconLink.faviconLink} />
        <title>{faviconLink.title}</title>
      </Helmet>
    );
  }

  return (
    <Helmet>
      <link rel="icon" href={faviconLink.faviconLink} />
      <title>{faviconLink.title}</title>
    </Helmet>
  );
}

export default connector(DynamicFavicon);