import { ApplicationState } from '../../../store/index';
import { connect, ConnectedProps } from 'react-redux';
import { SetClearHttpErrorMessages } from './../../../store/HttpErrorMessages';
import { SetClearHttpWarningMessages } from '../../../store/HttpWarningMessages';
import ModalList from './ModalList';

const mapStateToProps = (state: ApplicationState) => ({
	errorMessagesInfo: state.httpErrorMessages.httpErrorMessages,
	warningMessagesInfo: state.httpWarningMessages.httpWarningMessages
});

const mapDispatchToProps = (dispatch: any) => {
	return {
		closeErrorModal: (id: string) => dispatch(SetClearHttpErrorMessages(id)),
		closeWarningModal: (id: string) => dispatch(SetClearHttpWarningMessages(id))
	}
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const HttpErrorModal = (props: PropsFromRedux) => {

	return (<>
		{props.errorMessagesInfo.map((item) => (
			<ModalList
				type="error"
				title='Ошибки обращения к серверу'
				messages={item.messages}
				id={item.id}
				handleClose={props.closeErrorModal}
			/>
		))}

		{props.warningMessagesInfo.map((item) => (
			<ModalList
				type="warning"
				title='Предупреждения'
				messages={item.messages}
				id={item.id}
				handleClose={props.closeWarningModal}
			/>
		))}

	</>)
}

export default connector(HttpErrorModal);