/* сервер */

export const apiMainAuth :      string = "api/main/auth";
export const apiMainAuthAuto :  string = "api/main/authauto";

export const apiStartUpdate :   string = "api/main/startupdate";
export const apiUserInfo :      string = "api/main/userinfo";
export const apiReloginUser :   string = "api/main/relogin";
export const apiCheckUser : string = "api/main/checkUser"; // проверка login + password 
export const apiStartDbUpdate :   string = "api/main/startDbUpdate";
 
export const apiVoAuth :            string = "api/voauth/auth";
export const apiVoAuthAuto :        string = "api/voauth/authauto";
export const apiVoAuthAutoWeb :        string = "api/voauth/authautoweb";

export const apiVoLogOut :          string = "api/voauth/logout";
export const apiVoAuthWeb : string = "api/voauth/authweb";
export const apiVoAuthPasswordRecovery : string = "api/voauth/passwordRecovery";


export const apiVoTestList : string = "api/votest/area";
export const apiVoTest : string = "api/votest/";
export const apiVoTestMaterialList : string = "api/votest/testmaterials/area";
export const apiVoTestExportDocFile : string = "api/votest/exportdoc";

export const apiVoSkrinTest : string = "api/votestsskrin";

export const apiVoAdminSettings :   string = "api/voadminsettings/";
export const apiVoAdminSmtpStatus : string = "api/voadminsettings/statussmtp";
export const apiVoAdminSmtpTest : string = "api/voadminsettings/testsmtp";

export const apiSettionLog : string = "api/logsConfig";

export const apiImportDataGetAdminAuth : string = "api/ImportData/getAdminAuth";
export const apiImportDataGetTempFolder : string = "api/ImportData/getTempFolder";
export const apiImportDataCheckTempFolder : string = "api/ImportData/checkTempFolder";
export const apiImportDataStartUnZip : string = "api/ImportData/startUnZip";
export const apiImportDataGetStatusUnZip : string = "api/ImportData/getStatusUnZip";
export const apiImportDataCheckUnZipFolder : string = "api/ImportData/checkUnZipFolder";
export const apiImportDataCheckLogins: string = "api/ImportData/checkLogins";
export const apiImportDataCheckStudentLimit : string = "api/ImportData/checkStudentLimit";

export const apiImportDataCheckStartImport: string = "api/ImportData/startImport";
export const apiImportDataGetFullPathFileDb : string = "api/main/getFullPathFileDb";


export const apiAutoBackupConfigGetInfo : string = "api/CreateDbBackUp/getBackUpInfo";
export const apiAutoBackupConfigSetInfo : string = "api/CreateDbBackUp/saveBackUpInfo";


export const apiVoAdminSettingsSave :   string = "api/voadminsettings/save";
export const apiVoAdminSettingLogsConfig : string = "api/voadminsettings/saveConfigLogs";


export const apiVoCommonSettingGetProxy : string = "api/main/getproxyconf";
export const apiVoCommonSettingSetProxy : string = "api/main/setproxyconf";
export const apiVoCommonSettingSetProxyAuth : string = "api/main/setproxyconfAuth";

export const apiVoCommonTestProxy : string = "api/main/testServ";


export const apiVoAdminSdl : string = "api/voadminsdl";
export const apiVoAdminSdlSetActive : string = "api/voadminsdl/setactive";
export const apiVoAdminSdlSendNewPassword : string = "api/voadminsdl/sendnewpass";
export const apiVoAdminSdlGetLinkNewPassword = "api/voadminsdl/getlinknewpass"

export const apiVoAdmintruct : string = "api/vostruct";

export const apiVoAdminStructCheckLinks = "api/vostruct/checkLinks";

export const apiVoCheckPassword :   string = "api/voauth/temppassword";
export const apiVoSetUserPassword : string = "api/voauth/setpassword";

export const apiVoAreaSaveLogo : string = "api/voareas/savelogo";
export const apiVoAreaDropLogo : string = "api/voareas/droplogo";
export const apiVoAreas : string = "api/voareas"; 
export const apiVoAreasVoWeb : string = "api/vowebareas";
export const apiVoAreasGetLast = "api/voareas/getlast"

export const apiVoAreasSetUnActive : string = "api/voareas/setunactive";
export const apiVoAreasSetActive : string = "api/voareas/setactive";
export const apiVoAreasWebSetActive : string = "api/vowebareas/setactive";


// новости 
export const apiVoGetAllNews : string  = "api/vonews/allskrinnews";
export const apiVoGetSkrinNews : string  = "api/vonews/skrinnews";
export const apiVoGetSdlNews : string  = "api/vosdlnews/show"; 
export const apiVoSkrinNewsGetStudentStatus : string = "api/vonews/showstudentstatus";
export const apiVoSkrinNewsSetStudentStatus : string = "api/vonews/setshowstudentstatus";
export const apiVoGetAllNewsSearch : string  = "api/vonews/searchskrinnews";

export const apiVoSdlNews : string = "api/vosdlnews/"
export const apiVoSdlNewsChangeStatus :string = "api/vosdlnews/changestatus";
export const apiVoSdlNewsChangeAllStatus : string = "api/vosdlnews/changeallstatus";

// документы 
export const apiVoGetAllDocs : string  = "api/vodocsskrin/allskrindocs"; 
export const apiVoGetSdlDocs : string = "api/vodocssdl";
export const apiVoGetSdlDocsSearch : string = "api/vodocssdl/searchTree";

// видео 
export const apiVoGetSdlVideo : string = "api/vovideosdl";
export const apiVoGetSkrinVideo : string = "api/vovideoskrin"; 
export const apiVoGetSdlVideoSearch : string = "api/vovideosdl/searchTree";
export const apiVoGetSkrinVideoUrl : string = "api/vovideoskrin/getVideoLink"; // получение ссылки на видео СКРИН




// перфиксы для работы с виджетом файловой системы 
export const apiFileViewPrefixLoadFile : string = "/loadfile"; 
export const apiFileViewPrefixGetList : string = "/getlist"; 
export const apiFileViewPrefixSave : string = "/save";
export const apiFileViewPrefixDownLoad : string = "/download";
//export const apiFileViewPrefixDelete : string = "/delete"; -- по т.з. удаление отменено для облака 

// студенты 
export const apiVoStudents : string = "api/vostudent";
export const apiVoStudentsWebVo : string = "api/vowebstudent";

export const apiVoStudentsArhive : string = "api/vostudent/searcharhivepage";

export const apiVoStudentChangeArhiveStatus : string = "api/vostudent/changeStudentArhiveStatus";
export const apiVoStudentChangeArhiveStatusWeb : string = "api/vowebstudent/changeStudentArhiveStatus";

export const apiVoStudentsNewLogin : string = "api/vostudent/getNewLogin";
export const apiVoStudentsLoadList : string = "api/vostudent/loadlist";
export const apiWebVoStudentsLoadList : string = "api/vowebstudent/loadlist";

export const apiVoStudentsSendNewPassword : string = "api/vostudent/sendnewpass";
export const apiVoStudentsGetLinkNewPassword : string  = "api/vostudent/getlinknewpass"
export const apiVoStudentsTreeView : string  = "api/vostruct/searchTree/getlist";
export const apiVoDepartamenTreeView : string = "api/vostruct/searchTreeDepartament/getlist";
export const apiVoStudentsTreeViewTraining : string = "api/vostruct/searchTree/getshowlist";

export const apiVoStudentsGetShortInfo : string = "api/vostudent/getshortinfo";
export const apiVoStudentSearchHistory : string = "api/vostudent/searchHistory";
export const apiVoStudentJournals : string = "api/vostudentjournal";
export const apiVoStudentJournalsGetPdf : string = "api/vostudentjournal/getpdf";
export const apiVoStudentJournalsGetDoc : string = "api/vostudentjournal/getdoc";

export const apiVoStudnetJournalSaved : string = "api/vostudentjournalsaved";
export const apiVoStudentJournalJournalFile : string = "api/vostudentjournal/getsavedjournal";
export const apiVoStudentsFilterJournalsList : string = "api/vostudentjournal/getfilterjournals";

export const apiVoStudentsFilterJournalsCreateFilePdf : string = "api/vostudentjournal/getfileIdjournalsPdf";
export const apiVoStudentsFilterJournalsCreateFileDoc : string = "api/vostudentjournal/getfileIdjournalsDoc";

export const apiVoStudentsCertList : string = "api/vostudentcert";

export const apiVoStudentsFilterCount : string = "api/VoStudentTrainig/getCountstudentcerts";
export const apiVoStudentsCertGetFileId : string = "api/VoStudentTrainig/getStudentCertsId";
export const apiVoStudentsCertGetFileStatus : string = "api/VoStudentTrainig/getStudentCertsStatus";
export const apiVoStudentsCertStartProcess : string = "api/VoStudentTrainig/getstudentcertsprocess";
export const apiVoStudentsCertLoad : string = "api/VoStudentTrainig/getstudentcerts";

export const apiVoTraningStartGenCerts : string = "api/VoStudentTrainig/traningStartGenCerts";

export const apiVoStudentCertCountById : string = "api/VoStudentTrainig/getCountStudentCerts/student"; // кол-во сертификатов по id студента 
export const apiVoStudentCertStartGenById : string = "api/VoStudentTrainig/traningStartGenCertsStudent"; // запуск генерации по id студента 


//обучение 
export const apiVoTrainingSelectorOptions : string = "api/votraining/selectoroptions";
export const apiVoTraining : string = "api/votraining/";
export const apiVoTrainingStartSendMails : string = "api/votraining/startsendmails";
export const apiVoTrainingChangeStatus : string = "api/votraining/changestatus";
export const apiVoTrainingSendMailsInvit : string = "api/votraining/sendinvit";
export const apiVoTrainingProgress : string = "api/votraining/getprogress";
export const apiVoTrainingSendStudentMailInvit : string = "api/votraining/sendinvitstudent";
export const apiVoTrainingUpdateStudentList : string = "api/votraining/updateTrainingStudentList";
export const apiVoTrainingSearch : string = "api/votraining/searchtraining";

export const apiVoTrainingGetSdlCertPdf : string = "api/vostudenttrainig/getsdlcertPdf";
export const apiVoTrainingGetSdlCertDoc : string = "api/vostudenttrainig/getsdlcertDoc";

export const apiVoTrainingGetSdlTestResult : string = "/api/vostudenttest/getsdlresultpdf";
export const apiVoTrainginJournal : string = "api/vostudentjournal/gettrainingjournals";
export const apiVoTrainingJournalGetPdf : string = "api/vostudentjournal/trainingjournalpdf";
export const apiVoTrainingJournalGetDoc : string = "api/vostudentjournal/trainingjournaldoc";

// новости студента
export const apiVoStudentSkrinNews :  string = "api/vostudentnews/allskrinnews";
export const apiVoStudentSdlNews :    string = "api/vostudentnews/allsdlnews";
export const apiVoStudentSkrinNewsStatus : string = "api/vostudentnews/getshowskrinnewsstatus";
export const apiVoStudentNewsSearch : string  = "api/vostudentnews/searchskrinnews";

// обучение студента 
export const apiVoStudentTraining : string = "api/vostudenttrainig";
export const apiVoStudentTrainingDescript : string = "api/vostudenttrainig/getinfo";
export const apiVoStudentTrainingDocs : string = "api/vostudenttrainig/getdocs";
export const apiVoStudentTrainingVideo : string = "api/vostudenttrainig/getvideo";
export const apiVoStudentTrainingTests : string = "api/vostudenttrainig/gettests";


export const apiVoLoadSkrinDocById : string = "api/vodocsskrin/loadbyid/";
export const apiVoLoadSdlDocById : string = "api/vodocssdl/download/";
export const apiVoLoadSdlVideoById : string = "api/vovideosdl/download/student/";

export const apiVoWriteVideoSkrinEvent :  string = "api/vovideoskrin/studenthistory";
export const apiVoWriteVideoSdlEvent :    string = "api/vovideosdl/studenthistory";

// тесты студента 

export const apiVoStudentSdlTest : string = "api/vostudenttest/getsdltest";
export const apiVoStudentSkrinTest : string = "api/vostudenttest/getskrintest";

export const apiVoStudentStartTestSdl :   string = "api/vostudenttest/starttestsdl";
export const apiVoStudentStartTestSkrin : string = "api/vostudenttest/starttestskrin";


export const apiVoStudentGetQuestion : string = "api/vostudenttest/question";
export const apiVoStudentSetQuestionAnswer : string = "api/vostudenttest/setanswerforquestion";
export const apiVoStudentGetTestResult : string = "api/vostudenttest/getresult";
export const apiVoStudentGetTestFinishInfo : string = "api/vostudenttest/finishInfo";
export const apiVoStudentTestFinishedForward : string = "api/vostudenttest/finishForward";

export const apiVoStudentGetTestResultPdf : string = "api/vostudenttest/getresultpdf";
export const apiVoStudentTrainingConfirm : string = "api/vostudenttrainig/confirm"; 

export const apiVoStudentGetCertPdf : string = "api/vostudentTrainig/getCertPdf";
export const apiVoStudentGetCertDoc : string = "api/vostudentTrainig/getCertDoc";

export const apiVoFeedbackSend : string = "api/VoFeedback/Send";
export const apiVoFeedbackSendSupport: string = "api/VoFeedback/SendSupport";


export const apiLoadLogo : string = "/api/voareas/logo/";
export const apiWriteError :    string = "api/clienterror/boundarieserror";


/* контроллеры реестров */
export const apiMVKRegistr :    string = "api/mvkregistry";
export const api639PRegistr :   string = "api/fsfmregistry";

/** общая авторизация для блоков */
export const ModuleAuthentication : string = "api/ModuleAuthentication/ModuleLogin"; 
export const ModuleAuthenticationLogout : string = "api/ModuleAuthentication/ModuleLogout"; 
export const ModuleAuthenticationModuleLoginAuto : string = "api/ModuleAuthentication/ModuleLoginAuto";

/* префиксы к регистрам */
export const apiRegistryPrefixLoadFile :    string = "/loadfile";
export const apiRegistryPrefixGetListFile : string = "/files";
export const apiRegistryPrefixRemome :      string = "/remove/";

/* инструкции видео*/
export const apiVoInstructionVideo :  string = "api/voinstructionsvideo/allinstructionvideo";
export const apiVoInstructionVideoTag :  string = "api/voinstructionsvideo/allinstructionvideotags";


