import { useState } from "react"
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

interface IProps {
  value: string,
  setValue(value: string): void,

  label?: string | undefined
  disabled?: boolean | undefined
}

const PasswordInput = (props: IProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const label: string = props.label ? props.label : "Пароль";

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handelChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value: password } } = e;
    props.setValue(password);
  }

  return (
    <TextField
      label={label}
      disabled={props.disabled}
      fullWidth
      type={showPassword ? 'text' : 'password'}
      autoComplete="off"
      onChange={handelChangePassword}
      value={props.value}
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={handleClickShowPassword}
            onMouseDown={(event) => { event.preventDefault();}}
            onMouseUp={(event) => { event.preventDefault(); }}
            size="small"
          >
            {showPassword ? <Visibility /> : <VisibilityOff/>}
          </IconButton>
        )
      }}
    />
  )
}

export default PasswordInput;