import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    top: "5%"
  },
  closeButton: {
    position: 'absolute',
    right: "1%",
    top: "1%",
    color: "#ccc",
  },
});


interface IProps {
  open: boolean,
  onClose(): void,
  title: string,
  textQuery: string,
  onAgree(): void
}

const QueryDialog = (props: IProps) => {

  const classes = useStyles();

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      open={props.open}
      onClose={props.onClose}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle >
        <Grid container justifyContent="center">
          <Typography variant="h6" align='center'>{props.title}</Typography>
        </Grid>
        <IconButton aria-label="close" onClick={props.onClose} className={classes.closeButton}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography variant='subtitle2' >{props.textQuery}</Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onAgree} color="primary" variant='contained' autoFocus>
          Ок
        </Button>
        <Button onClick={props.onClose} color="secondary" variant='outlined'>
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default QueryDialog;