import { Switch, Route } from 'react-router-dom';
import { Suspense, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Loader from './Components/Common/Loader/Loader';
import { MainAuthPath, StartPagePath, StartUpdateInfoPath, CommonSettingPath, StartUpdateDbInfoPath } from './Interface/RouteConst';
import { apiMainAuthAuto, apiVoAuthAuto, apiVoAuthAutoWeb, ModuleAuthenticationModuleLoginAuto } from './Interface/ServerRouteConst';
import { IComponentStatus, IAuthStatus, IModules, ISiteList } from './Interface/MainTypes';
import { IVoAuthState, IVoAuthStateWeb } from './Interface/VoTypes';
import { get } from './Utils/Fetch';
import PrivateRouts from './Components/Main/PrivateRouts/PrivateRouts';
import { ApplicationState } from './store/index';
import ErrorView from './Components/Common/ErrrorView/ErrorView';
import UpdateInfo from './Components/Common/UpdateInfo/UpdateInfo';
import { connect, ConnectedProps } from 'react-redux';
import { SetAuth, SetAuthModules } from './store/AuthStatus';
import { SetVoAuth } from './store/VoAuth';
import StartUpdateInfo from './Components/Common/UpdateInfo/StartUpdateInfo';
import StartUpdateDbInfo from './Components/Common/UpdateInfo/StartUpdateDbInfo';
import lazyWithRetry from './Components/Common/LazyWithRetry/lazyWithRetry';
import ClearCacheComponent from './Components/Common/ClearCacheComponent/ClearCacheComponent';
import DbUpdateHasError from "./Components/Common/UpdateInfo/DbUpdateHasError";
import DynamicFavicon from './Components/Main/DynamicFavicon/DynamicFavicon';
import UpdateAppAlert from './Components/Common/UpdateAppAlert/UpdateAppAlert';
import UpdateDbInfo from './Components/Common/UpdateDbInfo/UpdateDbInfo';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import HttpErrorModal from './Components/Common/HttpErrorModal/HttpErrorModal';
import HttpRequest from './store/SkrinHttpRequest';
import { IKaFlAuthState } from './Interface/KaFlTypes';
import { SetKalAuth } from './store/KaFlAuth';

// import TestSkrinHttpRequest from './Components/Main/TestSkrinHttpRequest/TestSkrinHttpRequest';
// import { TestSkrinHttpRequestPath } from './Interface/RouteConst';

const MainAuth = lazyWithRetry(() => import('./Components/Main/Auth/AuthPage'));
const StartPage = lazyWithRetry(() => import('./Components/Main/StartPage/StartPage'));
const CommonSettingPage = lazyWithRetry(() => import('./Components/Main/CommonSetting/CommonSettingPage'));


const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '95vh'
	},
	footer: {
		marginTop: 'auto',
		minHeight: '5vh',
		textAlign: "center",
		width : "100%", 
		minWidth : "435px"
	},
	link: {
		color: "#002984",
		margin: "10px",
		textDecoration: "none"
	},
	toolbar: {
		flexWrap: 'wrap',
	}
});


const mapStateToProps = (state: ApplicationState) => ({
	appError: state.error?.error,
	appHasUpdate: state.appAuth.appAuth.hasUpdate,
	appStartUpdate: state.appAuth.appAuth.updateProcess,
	stateType: state.appAuth.appAuth.stateType,
	blockDbChanges: state.appAuth.appAuth.blockDbChanges,
	dbUpdateProcessHasError: state.appAuth.appAuth.dbUpdateProcessHasError,
	hasLongScriptDbUpdate: state.appAuth.appAuth.hasLongScriptDbUpdate
});

export const mapDispatchToProps = (dispatch: any) => {
	return {
		setAuth: (auth: IAuthStatus) => dispatch(SetAuth(auth)),
		setVoAuth: (auth: IVoAuthState) => dispatch(SetVoAuth(auth)),
		updateModules: (modules: Array<IModules> | null) => dispatch(SetAuthModules(modules)),

		updateKaFLAuth: (auth: IKaFlAuthState) => dispatch(SetKalAuth(auth)),

		getAuthStateKaFl: () =>
			dispatch(HttpRequest<IKaFlAuthState>(`${ModuleAuthenticationModuleLoginAuto}/${ISiteList.kaFl}`, "GET")),
	}
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function App(props: PropsFromRedux) {
	const [status, setStatus] = useState<IComponentStatus>('idle');

	useEffect(() => {
		if (props.stateType === "Desktop") {
			authDesktop();
		}
		else {
			authWeb();
		}
	}, [props]);


	const authDesktop = () => {
		if (
			(props.appError === null) &&
			(props.appHasUpdate === false) &&
			(props.appStartUpdate === false) &&
			(props.blockDbChanges === false) &&
			(props.dbUpdateProcessHasError === false)
		) {
			get<IAuthStatus>(apiMainAuthAuto)
				.then((response: IAuthStatus) => {
					props.setAuth(response);
					const needVoAuth: boolean = (response.isAuth &&
						(response.updateProcess === false) &&
						(response.blockDbChanges === false) &&
						(response.dbUpdateProcessHasError === false)
					);

					if (needVoAuth) {
						subAuthModule();
					}
					else {
						setStatus('success');
					}
				})
		}
	}

	const authWeb = () => {
		if ((props.appError === null)) {
			get<IAuthStatus>(apiMainAuthAuto)
				.then((response: IAuthStatus) => {
					props.setAuth(response);
					if (response.isAuth) {
						get<IVoAuthStateWeb>(apiVoAuthAutoWeb)
							.then((response: IVoAuthStateWeb) => {
								props.setVoAuth(response.voAuth);
								if (response.voAuth.isAuth && response.modules && response.modules.length > 0) {
									props.updateModules(response.modules);
								}
								setStatus('success');
							})
					}
					else {
						setStatus('success');
					}
				})
		}
	}

	//авторизация подчиненных сайтов - при условии прохождения MainAuth
	const subAuthModule = async () => {
		// при вызыве status === 'idle' 

		const [voAuthResponse, kaFlAuthResponse] = await Promise.all([
			get<IVoAuthState>(apiVoAuthAuto),
			props.getAuthStateKaFl()
		]);


		if (voAuthResponse) {
			props.setVoAuth(voAuthResponse);
		}

		if (kaFlAuthResponse) {
			props.updateKaFLAuth(kaFlAuthResponse);
		}

		setStatus('success'); // запросы завершены 
	}

	const classes = useStyles();

	if (props.appError !== null)
		return <ErrorView />;

	if (props.appStartUpdate)
		return <StartUpdateInfo />;

	if (props.dbUpdateProcessHasError)
		return <DbUpdateHasError />;

	if ((props.stateType === "Desktop") && (props.blockDbChanges))
		return <StartUpdateDbInfo />;


	if (status === 'pending' || status === 'idle')
		return Loader;

	const defaultTheme = createTheme();

	return (
		<div className={classes.root}>
			<ThemeProvider theme={defaultTheme} >
				<CssBaseline />
				<DynamicFavicon />
				<Grid container spacing={0} justifyContent="center">
					<Grid item={true} lg={12} md={12} xs={12} sm={12} >
						<UpdateAppAlert />
						<ClearCacheComponent />
						<HttpErrorModal />
						<Suspense fallback={Loader} >
							<Switch>
								<Route path={StartPagePath} component={StartPage} exact />
								<Route path={StartUpdateInfoPath} component={UpdateInfo} />
								<Route path={StartUpdateDbInfoPath} component={UpdateDbInfo} />
								<Route path={MainAuthPath} component={MainAuth} />
								<Route path={CommonSettingPath} component={CommonSettingPage} />

								{/* <Route path={TestSkrinHttpRequestPath} component={TestSkrinHttpRequest} />  */}
								{/* - тестовый компонет отправки запросов   /main/testSkrinRequest*/}

								<PrivateRouts />
							</Switch>
						</Suspense>
					</Grid>
				</Grid>
				<footer className={classes.footer}>
					<Grid container spacing={0} justifyContent="center">
						<Grid item={true}>
							<span>©1999-{new Date().getFullYear()} ООО «СКРИН»</span>
						</Grid>
					</Grid>
				</footer>
			</ThemeProvider>
		</div>
	);
};

export default connector(App);