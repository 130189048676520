import { Action, Reducer } from 'redux';
import { ISkrinResponseMessages } from './../Interface/MainTypes';


export interface HttpWarningMessagesState {
    httpWarningMessages: Array<ISkrinResponseMessages>
}

export interface SetHttpWarningMessagesAction { type: 'SET_HTTP_WARNING_MESSAGES_INFO', messages: ISkrinResponseMessages }
export interface SetClearHttpWarningMessagesAction { type: 'SET_HTTP_WARNING_MESSAGES_CLEAR_INFO', id: string }

export const SetHttpWarningMessages = (messages: ISkrinResponseMessages): SetHttpWarningMessagesAction => (
    { type: 'SET_HTTP_WARNING_MESSAGES_INFO', messages }
)

export const SetClearHttpWarningMessages = (id: string): SetClearHttpWarningMessagesAction => (
    { type: 'SET_HTTP_WARNING_MESSAGES_CLEAR_INFO', id }
)

type KnownAction = SetHttpWarningMessagesAction | SetClearHttpWarningMessagesAction;

export const reducer: Reducer<HttpWarningMessagesState> = (state: HttpWarningMessagesState | undefined, incomingAction: Action): HttpWarningMessagesState => {

    if (state === undefined) {
        return { httpWarningMessages: [] };
    }
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'SET_HTTP_WARNING_MESSAGES_INFO':
            return { httpWarningMessages : [...state.httpWarningMessages, action.messages] };
        case 'SET_HTTP_WARNING_MESSAGES_CLEAR_INFO':
            const newItems : Array<ISkrinResponseMessages> = state.httpWarningMessages.filter(item => (false === (item.id === action.id)));
            return { httpWarningMessages: newItems };
        default:
            return state;
    }

};

